.Header {}
.Header .carousel-item{
    width: 100vw;
}

span.fa{
    padding-right: 12px;
}
.placeholder{
    width: 100px;
    display: inline-block;
    background-color: gray;
}