.Maintenance {
    padding: 72px 0;
}.d-flex{
    flex-wrap: wrap;
}

.action-btn{
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%,0%);
    /* border:  1px solid red; */
    width: 100%;
}
.maintenance-formule-item{
    background-color: white !important;
}
.maintenance-formule-item h2{
    text-transform: uppercase;
    font-size: 1.2rem;
    color: white;
    background-color: #24273e;
}
.maintenance-formule-item h3{
    text-transform: uppercase;
    font-size: 0.7rem;
    border: 1px solid #24273e;
    font-weight: bold;
}

.maintenance-formule-item .d-flex{
    flex-direction: column;
}
.maintenance-formule-item del{
    font-size: 0.8rem;
    color: red;
}